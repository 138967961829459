<template>
  <div>
    <el-form
      ref="addForm"
      :model="addForm"
      label-width="90px"
      style="display: flex"
      :rules="addFormRules"
    >
      <div class="el-lt" style="width: 85%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item
              label="供应商"
              prop="vendorId"
              :class="$i18n.locale"
            >
              <el-select
                v-model="addForm.vendorId"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="采购订单" :class="$i18n.locale">
              <el-input
                v-model="addForm.purchaseOrderCode"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收货仓库" :class="$i18n.locale">
              <el-select
                v-model="addForm.collectWarehouseId"
                clearable
                multiple
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in warehouseOption"
                  :key="item.id"
                  :label="item.warehouseName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.Style')">
              <el-select
                v-model="addForm.style"
                value-key="id"
                clearable
                filterable
              >
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.Color')">
              <el-select
                v-model="addForm.color"
                clearable
                filterable
                @visible-change="_queryColorList"
              >
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.colorName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.Size')">
              <el-select
                v-model="addForm.sizes"
                clearable
                filterable
                @visible-change="_querySizeList"
              >
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :label="item.size"
                  :value="item.size"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="收货时间">
              <el-date-picker
                v-model="value1"
                value-format="yyyy-MM-dd"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <el-upload
              ref="uploadRef"
              action=""
              class="el-button padding-none"
              :http-request="uploadFile"
              :limit="1"
              :show-file-list="false"
              :file-list="fileList"
              :auto-upload="false"
              :on-change="fileChange"
            >
              <el-button type="primary" size="small">{{
                $t("page.import")
              }}</el-button>
            </el-upload>
            <el-button
              type="primary"
              class="ml-4"
              @click="downTemplate"
            >模板下载</el-button>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width: 25%">
        <el-col class="row-center">
          <el-button type="primary" :loading="returnPageListLoading" @click="_returnPageList">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button type="text" @click="showRow = !showRow">{{
            showRow ? $t("page.hide") : $t("page.expand")
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <TableSelectAction
      :quantity="multipleSelection.length"
      @reset="handleTableSelectReset"
    />
    <ReturnTable
      ref="table"
      :table-datas-loading="returnPageListLoading"
      :table-datas="tableDatas"
      @getMultipleSelection="getMultipleSelection"
    />
    <el-row
      class="mt-4"
    ><el-col
      :offset="10"
    ><el-button type="primary" @click="submit">提 交</el-button></el-col></el-row>
    <!-- 导入 -->
    <el-dialog
      title="导入"
      :visible.sync="importDialog"
      width="450px"
      :close-on-click-modal="false"
    >
      <span
        v-if="fileList != ''"
        class="info-message"
      >{{ $t("page.ConfirmImport") }} {{ fileList[0].name }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeImportDialog">取消</el-button>
        <el-button
          type="primary"
          @click="importDialogSubmit"
        >导入详情</el-button>
      </span>
    </el-dialog>
    <!-- 导入详情 -->
    <el-dialog
      :visible.sync="uploadTableVisible"
      width="80%"
      title="导入详情"
      append-to-body
      @close="close"
    >
      <el-row type="flex" justify="end">
        <el-button
          type="primary"
          @click="handleDeletedetails()"
        >删除</el-button>
      </el-row>

      <el-form ref="detailFormRef" :model="detailForm">
        <el-table
          ref="uploadTableDataRef"
          :data="detailForm.importdata"
          :header-cell-style="{ background: '#fafafa' }"
          tooltip-effect="dark"
          max-height="400px"
          class="mt-3"
          :row-style="{height: '90'}"
          @selection-change="dialogSelectionChange"
        >
          <el-table-column type="selection" width="60" align="center" />
          <el-table-column
            :label="$t('page.No')"
            type="index"
            width="80"
            align="center"
          />
          <el-table-column prop="errorMsg" label="错误信息" />
          <el-table-column prop="purchaseOrderCode" label="采购订单" />
          <el-table-column prop="style" label="STYLE" />
          <el-table-column prop="color" label="COLOR" />
          <el-table-column prop="size" label="SIZE" />
          <el-table-column prop="collectPairs" label="收货数量" />
          <el-table-column prop="returnPairs" label="退货数量" />
          <el-table-column prop="returnWarehouseId" label="退货仓库" width="280px">
            <template slot-scope="scope">
              <el-form-item
                :prop="'importdata.' + scope.$index + '.collectWarehouseName'"
                :rules="{ required: true, message: '该笔数据存在多个收货仓库，请确认收货仓库', trigger: ['change']} "
              >
                <el-select
                  v-model="scope.row.collectWarehouseName"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in scope.row.collectWarehouseNameList"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>

            </template>
          </el-table-column>

        </el-table>
      </el-form>

      <el-row type="flex" justify="end" style="margin-top: 20px">
        <el-button @click="close">取消</el-button>
        <el-button
          type="primary"
          :loading="Loading"
          @click="submitImportTable"
        >提交</el-button>
      </el-row>
    </el-dialog>
    <!-- 提交弹框 -->
    <el-dialog title="提示" :visible.sync="returnDialog" width="40%">
      <span>本次提交退货数量为{{ amount }} 金额为{{
        sum
      }}
        提交之后进入审批流是否继续！</span>
      <el-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="退货原因" prop="returnGoodsReason">
          <el-select
            v-model="form.returnGoodsReason"
            clearable
            :placeholder="$t('page.selectPlaceholder')"
          >
            <el-option label="仓库稽查NG" value="1" />
            <el-option label="批量性贴标异常" value="2" />
            <el-option label="其他原因" value="3" />
          </el-select>
        </el-form-item>

        <el-form-item label="处理方式" prop="dealWay">
          <el-select
            v-model="form.dealWay"
            clearable
            filterable
          >
            <el-option
              v-for="item in _getAllCommodityDict('AUD_DEAL_WAY')"
              :key="item.val"
              :label="item.label"
              :value="item.val"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="备注">
          <el-input v-model="form.remarks" type="textarea" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="returnDialog = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="returnSubmitLoading"
          @click="returnSubmit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryTerraceList, findDownloadUrl, listByPlatform, queryStyleList, queryColorList, querySizeList, queryVendorList, returnSubmit, returnBeforePageList, queryWarehouseList, returnGoodsBeforeImportView, returnGoodsBeforeImport } from '@/api/scm-api'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import TableSelectAction from '@/components/TableSelectAction'
// import Pagination from '@/components/Pagination'
import ReturnTable from '../components/index'
import { downloads } from '@/utils'

export default {
  components: { TableSelectAction, ReturnTable },
  mixins: [commodityInfoDict],

  data() {
    return {
      returnPageListLoading: false,
      returnSubmitLoading: false,
      warehouseOption: [],
      tableDatas: [],
      form: {},
      returnDialog: false,
      multipleSelection: [],
      file: '',
      fileList: [],
      vendorOptions: [],
      addForm: {
        vendorId: []
      },
      value1: [],
      showRow: false,
      platforoptions: [],
      alldatas: [],
      siteoptions: [],
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      importDialog: false,
      uploadTableVisible: false,
      detailForm: {
        importdata: []
      },

      dialogselectlist: [],
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      Loading: false,
      rules: {
        returnGoodsReason: [
          { required: true, message: this.$t('page.required'), trigger: 'change' }
        ]
      },
      addFormRules: {
        vendorId: [
          { required: true, message: this.$t('page.required'), trigger: 'change' }
        ]
      },
      sum: 0,
      amount: 0
    }
  },
  computed: {
    returnBeforePageListParams() {
      const { vendorId, purchaseOrderCode, collectWarehouseId, sizes, style, color } = this.addForm
      const [startCollectTime, endCollectTime] = this.value1 || []
      return Object.assign({}, { startCollectTime, endCollectTime }, { vendorId: vendorId ? vendorId.toString() : '', purchaseOrderCode, collectWarehouseId: collectWarehouseId ? collectWarehouseId.toString() : '', sizes, style, color }, this.pager)
    },
    submitParams() {
      return this.dialogselectlist.map(item => {
        return { ...item, collectWarehouseId: this.handleId(item) }
      })
    }
  },
  watch: {
    'returnDialog'(val) {
      if (!val) {
        this.$refs.ruleForm.resetFields()
        this.form = {}
      }
    },
    'uploadTableVisible'(val) {
      if (!val) {
        this.$refs.detailFormRef?.clearValidate()
      }
    }
  },
  mounted() {
    this._queryWarehouseList()
    this._returnPageList(0)
    this._queryVendorList()
    this._queryTerraceList()
    this._querySiteList()
    this._queryStyleList()
  },
  methods: {
    handleId(item) {
      const { collectWarehouseName, collectWarehouseNameList = [], collectWarehouseIdList = [] } = item
      const idx = collectWarehouseNameList.findIndex(item => item === collectWarehouseName)
      return collectWarehouseIdList.find((item, index) => index === idx)
    },
    close() {
      this.uploadTableVisible = false
      this.importDialog = false
      this.$refs.uploadRef?.clearFiles()
    },
    getMultipleSelection(val) {
      this.multipleSelection = val
      this.sum = 0
      this.amount = 0
      if (this.multipleSelection && this.multipleSelection.length) {
        for (var i = 0; i < this.multipleSelection.length; i++) {
          this.sum += Number(this.multipleSelection[i].returnTotalPrice)
          this.amount += Number(this.multipleSelection[i].returnPairs)
        }
      }
    },
    async _queryWarehouseList() {
      const { datas } = await queryWarehouseList()
      this.warehouseOption = datas
    },
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    // 新增页面分页查询
    async _returnPageList(type) {
      try {
        if (this.addForm.vendorId && !this.addForm.vendorId.length) return
        this.returnPageListLoading = true
        type === 0 ? this.pager.current = 1 : ''
        const { code, datas } = await returnBeforePageList(this.returnBeforePageListParams)
        this.tableDatas = datas
        code === 0 ? this.returnPageListLoading = false : ''
      } finally {
        this.returnPageListLoading = false
      }
    },
    // size
    async _querySizeList() {
      const { id } = this.styleOptions.find(item => item.styleName === this.addForm.style) || []
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    // style
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // color
    async _queryColorList() {
      const { id } = this.styleOptions.find(item => item.styleName === this.addForm.style) || []
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    dialogSelectionChange(val) {
      this.dialogselectlist = val
    },
    async uploadFile() {
      const form = new FormData()
      form.append('file', this.file)
      const { datas } = await returnGoodsBeforeImportView(form)
      this.detailForm.importdata = datas.map(item => {
        return { ...item, collectWarehouseName: item.collectWarehouseNameList?.length === 1 ? item.collectWarehouseNameList[0] : '' }
      })
      this.uploadTableVisible = true
    },
    handleTableSelectReset() {
      this.multipleSelection = []
      this.$refs.table.$refs.tableDatas.clearSelection()
    },
    closeImportDialog() {
      this.file = ''
      this.fileList = []
      this.importDialog = false
    },
    fileChange(file, fileList) {
      this.fileList = fileList
      this.file = file.raw
      if (this.fileList.length !== 0) {
        if (this.beforeUpload(file)) {
          this.importDialog = true
        }
      } else {
        this.importDialog = false
      }
    },
    beforeUpload(file) {
      const Xls = file.name.split('.')
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('支持10M以内文件')
        return false
      }
      if (Xls[Xls.length - 1] === 'xls' || Xls[Xls.length - 1] === 'xlsx') {
        return true
      } else {
        this.$message.error('只支持.xls与.xlsx导入')
        return false
      }
    },
    resetClick() {
      this.addForm = this.$options.data.call(this).addForm
      this.value1 = []
      this.addForm.vendorId = []
      this._returnPageList(0)
    },
    handleImport() {
      this.importVisible = true
    },
    async downTemplate() {
      const { datas } = await findDownloadUrl('RETURN_GOODS_IMPORT_TEMPLATE')
      downloads(datas)
    },
    async _queryTerraceList() {
      const { datas } = await queryTerraceList()
      this.platforoptions = datas
    },
    async _querySiteList(id) {
      const { datas } = await listByPlatform({ sellPlatformId: id })
      this.siteoptions = datas
    },
    styleSelectChange() { },
    // 进入导入详情页面
    importDialogSubmit() {
      this.$refs.uploadRef.submit()
    },
    // 删除详情列表
    handleDeletedetails() {
      if (this.dialogselectlist.length === 0) {
        this.$message({
          message: '请至少选择一条明细删除',
          type: 'warning'
        })
        return false
      }
      this.dialogselectlist.map(item => {
        var index = this.detailForm.importdata.findIndex(i => i.pairs === item.pairs && i.platform === item.platform &&
          i.purchaseOrderCode === item.purchaseOrderCode && i.site === item.site && i.size === item.size &&
          i.style === item.style && i.warehouse === item.warehouse)
        index !== -1 && this.detailForm.importdata.splice(index, 1)
      })
    },
    // 提交已选择的导入详情
    submitImportTable() {
      if (!this.dialogselectlist.length || this.dialogselectlist.some(item => item.errorMsg)) {
        this.$message({
          message: '请至少选择一条无错误信息明细导入',
          type: 'warning'
        })
        return false
      }
      this.$refs.detailFormRef.validate(async valid => {
        if (valid) {
          const { datas, code, msg } = await returnGoodsBeforeImport(this.submitParams)
          this.tableDatas = []
          datas.map(item => this.tableDatas.unshift(JSON.parse(JSON.stringify(item))))

          if (code === 0) {
            this.$notify({
              title: msg,
              message: msg,
              type: 'success'
            })
            this.close()
          }
        }
      })
    },
    submit() {
      if (!this.multipleSelection.length) {
        this.$message({
          message: '请至少选择一条数据',
          type: 'warning'
        })
        return
      }
      if (this.multipleSelection.find(item => item.returnPairs < 0 || item.returnPairs > item.surplusReturnPairs)) {
        this.$message.warning('本次退货数量填写有误')
        return
      }
      this.returnDialog = true
    },
    returnSubmit() {
      const { returnGoodsReason, remarks, dealWay } = this.form
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          try {
            this.returnSubmitLoading = true

            const { code, msg } = await returnSubmit({ returnGoodsReason, remarks, dealWay, returnGoodsBeforeList: this.multipleSelection })
            if (code === 0) {
              this.$message.success(msg)
              this.returnDialog = false
              this.handleTableSelectReset()
            }
          } finally {
            this.returnSubmitLoading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
